/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500&display=swap');

body {
  margin: 0;
  /* font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiOutlinedInput-root, .MuiPaper-root, .MuiAutocomplete-popper {
  border-radius: 0.5em !important;
  font-size: inherit !important;
}

.MuiAutocomplete-popper {
  margin-top: 0.5em !important;
}

.MuiPaper-root {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16) !important;
}

.MuiAutocomplete-endAdornment {
  align-items: center;
  display: flex;
}

@media (max-width: 1350px) {
  .headerSearch {
    display: none !important;
  }
}